import React from 'react';
import sampleProject1 from "../assets/sampleProject1.png";
import sampleProject2 from "../assets/sampleProject2.png";
import sampleProject3 from "../assets/sampleProject3.png";
import sampleProject4 from "../assets/sampleProject4.png";
import sampleProject5 from "../assets/sampleProject5.png";
import sampleProject6 from "../assets/sampleProject6.png";

const Portfolio = () => {
    const portfolios = [
        {
            id: 1, src: sampleProject1
        },
        {
            id: 2, src: sampleProject2
        },
        {
            id: 3, src: sampleProject3
        },
        {
            id: 4, src: sampleProject4
        },
        {
            id: 5, src: sampleProject5
        },
        {
            id: 6, src: sampleProject6
        },
    ]

  return (
    <div name='portfolio' className='md:px-14 bg-gradient-to-b from-blue-950 to-black w-full text-white min-h-screen'>
        <div className='max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full h-full'>
            <div className='pb-8'>
                <p className='text-4xl font-bold inline border-b-4 border-gray-500'>
                    Portfolio
                </p>
                <p className='py-6'>
                    This is currently empty sadly but not for much longer!
                </p>
            </div>

            <div className='grid sm:grid-cols-2 md:grid-cols-3 gap-8 px-12 sm:px-0'>
            {
                portfolios.map(({ id, src }) => (
                    <div key={id} className='shadow-md shadow-gray-600 rounded-lg'>
                    <img src={src} alt="" className='rounded-md duration-200 hover:scale-105'/>
                    <div className='items-center justify-center'>
                        <button className='w-1/2 px-6 py-3 mg-4 duration-200 hover:scale-105'>Demo</button>
                        <button className='w-1/2 px-6 py-3 mg-4 duration-200 hover:scale-105'>Code</button>
                    </div>
                </div>
                ))
            }
            </div>
        </div>
    </div>
  );
};

export default Portfolio;