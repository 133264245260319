import React, { useState } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import { Link } from 'react-scroll';

const NavBar = () => {
  const [nav, setNav] = useState(false);


  const links = [
    { id: 1, link: 'home' },
    { id: 2, link: 'about' },
    { id: 3, link: 'experience' },
    { id: 4, link: 'portfolio' },
    { id: 5, link: 'contact' },
  ];

  const handleLinkClick = (link) => {
    setNav(false);
  };

    // changes nav colour when scrolling
  const [navColour, setNavColour] = useState(false);

  return ( 
    <div className='md:sticky top-0 flex justify-between items-center 
    w-full h-20 text-whit fixe text-white bg-black px-4'>
      <div>
      <h1 className='text-5xl font-signature font-bold ml-2'>Nesar Torkamani</h1>
      </div> 
      <ul className='hidden md:flex'>
      {links.map(({ id, link }) => (
      <li key={id} className={navColour ? 'text-yellow-500 px-4 py-6 cursor-pointer capitalize font-mediu' : 'text-white px-4 py-6 cursor-pointer capitalize font-medium hover:text-yellow-500 hover:scale-110 duration-200'}>
        <Link to={link} smooth duration={500}>{link}</Link>
      </li>
      ))}
      </ul>

      <div onClick={() => setNav(!nav)} className='cursor-pointer pr-4 z-10 text-white md:hidden'>

      {nav ? <FaTimes size={30} /> : <FaBars size={30} />}
      </div>

      {nav && (
      <ul className='flex flex-col justify-center items-center absolute top-0 
      left-0 w-full h-screen bg-gradient-to-b from-black to-blue-950 text-white'>
        {links.map(({ id, link }) => (
        <li key={id} className='px-4 py-6 hover:text-yellow-500 hover:scale-110 
        cursor-pointer capitalize text-4xl'
          >
          <Link to={link} smooth duration={500} onClick={() => handleLinkClick(link)}>
            {link}
            </Link>
        </li>
        ))}
      </ul>
      )}
    </div>
  );
};

export default NavBar;