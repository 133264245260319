import React from 'react';
import HTML from '../assets/HTML.png';
import CSS from '../assets/CSS.png';
import JavaScript from '../assets/JavaScript.png';
import ReactImage from '../assets/ReactImage.png';
import WordPress from '../assets/WordPress.png';
import PHP from '../assets/PHP.png';
import Csharp from '../assets/Csharp.png';
import SQLServer from '../assets/SQLServer.png';
import VS from '../assets/VS.png';
import VScode from '../assets/VScode.png';
import Github from '../assets/Github.png';

const Experience = () => {
    const techs = [
        {
            id: 1,
            src: HTML,
            title: 'HTML',
            style: 'shadow-orange-500'
        },
        {
            id: 2,
            src: CSS,
            title: 'CSS',
            style: 'shadow-blue-500'
        },
        {
            id: 3,
            src: JavaScript,
            title: 'JavaScript',
            style: 'shadow-yellow-500'
        },
        {
            id: 4, 
            src: ReactImage,
            title: 'React',
            style: 'shadow-blue-300'
        },
        {
            id: 5,
            src: WordPress,
            title: 'WordPress',
            style: 'shadow-gray-500'
        },
        {
            id: 6,
            src: PHP,
            title: 'PHP',
            style: 'shadow-purple-800'
        },
        {
            id: 7,
            src: Csharp,
            title: 'C#',
            style: 'shadow-green-500'
        },
        {
            id: 8,
            src: SQLServer,
            title: 'SQL Server',
            style: 'shadow-green-500'
        },
        {
            id: 9,
            src: VS,
            title: 'Visual Studio',
            style: 'shadow-purple-500'
        },
        {
            id: 10,
            src: VScode,
            title: 'Visual Studio Code',
            style: 'shadow-blue-800'
        },
        {
            id: 11,
            src: Github,
            title: 'GitHub',
            style: 'shadow-gray-500'
        },
    ];
  return (
    <div name='experience' className='md:px-14 bg-gradient-to-b from-black to-blue-950 w-full text-white min-h-screen'>
        <div className='max-w-screen-lg mx-auto p-4 flex flex-col justify-center w-full h-full text-white'>
            <div className='pb-8'>
                <p className='text-4xl font-bold border-b-4 border-gray-500 inline'>
                    Experience
                </p>
                <p className='py-6'>
                    These are the technologies I have used. 
                </p>
            </div>
            <div className='w-full grid grid-cols-2 sm:grid-cols-3 gap-8 text-center py-8 px-12 sm:px-0'>
                {
                    techs.map(({id, src, title, style}) => (
                    <div key={id} className={`shadow-md hover:scale-105 duration-500 p-2 rounded-lg + ${style}`}>
                    <img src={src} alt="" className='w-20 mx-auto'/>
                    <p className='mt-4'>{title}</p>
                    </div>
                ))}
            </div>
        </div>
    </div>
  );
};

export default Experience;