import React from 'react';
import { FaGithub, FaLinkedin } from 'react-icons/fa';
import { HiOutlineMail } from 'react-icons/hi';

const SocialLinks = () => {

    const links = [
        {
        id: 1,
        child: (
            <>
            LinkedIn <FaLinkedin size={30}/>
            </>
        ),
        href: 'https://www.linkedin.com/in/nesar-torkamani-25841b152/',
        style: 'rounded-tr-md bg-blue-500'
    },
    {
        id: 2,
        child: (
            <>
            Github <FaGithub size={30}/>
            </>
        ),
        href: 'https://github.com/nesartorkamani', 
        style: 'bg-gray-800'
    },
    {
        id: 3,
        child: (
            <>
            Mail <HiOutlineMail size={30}/>
            </>
        ),
        href: 'https://mailto:nesartorkamani@hotmail.com',
        style: 'rounded-br-md bg-green-500'
    }
]

  return (
    <div className='mg-6 hidden lg:flex flex-col top-[35%] left-0 fixed'>
        <ul>
            {links.map(({id, child, href, style}) => (
            <li key={id} className={'flex justify-between items-center w-40 h-14 px-4 ml-[-100px] bg-gray-500 hover:ml-[10px] hover:rounded-md duration-300' + ' ' + style}>
            <a href={href} className='flex justify-between items-center w-full text-white' target='_blank' rel='noreferror'>
                <>
                {child}
                </>
            </a>
        </li>
            ))}
        </ul>
    </div>
  );
};

export default SocialLinks;