import React, { useState } from 'react';

const AutoScroll = () => {
  const [scrollToTop, setScrollToTopVisible] = useState(false);
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  window.setInterval(checkPagePosition, 10);

  function checkPagePosition () {
    console.log('working' +  window.scrollY)
   window.scrollY > 100 ? setScrollToTopVisible (true) : setScrollToTopVisible (false);
  }

  return (
    <div className='flex top-[75%] fixed right-2'>
      <div>
      {
      scrollToTop ? 
      <button
          onClick={handleScrollToTop}
          className='md:hidden hover:scale-105 mx-auto text-white w-fit p-4 flex 
          rounded-full bg-gradient-to-r from-cyan-500 to-blue-500'
        >
          ^
        </button> 
        : null 
        } 
      </div>
    </div>
  );
};

export default AutoScroll;
