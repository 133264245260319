import React from 'react';
import myimage from "../assets/myimage.png";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { Link } from 'react-scroll';

const Home = () => {
  return (
    <div name='home' className='md:h-screen md:px-14 w-full bg-gradient-to-b from-black to-blue-950 text-white'>
        <div className='max-w-screen-lg mx-auto flex flex-col items-center justify-center h-full px-4 md:flex-row'>
            <div className='flex flex-col justify-center h-full'>
                <h2 className='text-4xl sm:text-7xl font-bold text-white text-center md:text-left'>
                Hello, I'm <span className='text-yellow-500'>  Nesar.</span></h2>
                <h4 className='text-2xl pt-2 text-white text-center md:text-left'>
                A Web Developer</h4>
                    <p className='text-gray-500 py-4 text-center md:text-left'>
                        Welcome to my site! I am a web developer and I am currently learning React. Fun fact: This is built in React! 
                    </p>
                <div>
                    <Link to='portfolio' smooth duration={500} className='group hover:scale-105 mx-auto mb-8 md:mx-0 text-white w-fit px-6 py-3 my-2 flex rounded-3xl bg-gradient-to-r from-cyan-500 to-blue-500'>
                        Check out my empty portfolio! 
                        <span className='group-hover:rotate-90 duration-30'>
                        <MdOutlineKeyboardArrowRight size={25} className='ml-1'/>
                        </span>
                    </Link>
                </div>
            </div>
            <div>
                <img src={myimage} alt="my profile" className='mx-auto w-3/4 mb-8 md:w-full'/>
            </div>
        </div>
    </div>
  );
};

export default Home;