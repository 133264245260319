import Home from "./components/Home";
import NavBar from "./components/NavBar";
import SocialLinks from "./components/SocialLinks";
import About from "./components/About";
import Portfolio from "./components/Portfolio";
import Experience from "./components/Experience";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import AutoScroll from "./components/AutoScroll";

function App() {

  return (
      <div>
        <NavBar/>
        <Home/>
        <About/>
        <Experience/>
        <Portfolio/>
        <Contact/>
        <SocialLinks/>
        < AutoScroll/>
        <Footer />
      </div>
  );
}

export default App;
