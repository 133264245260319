import React from 'react';

const About = () => {
  return (
    <div name='about' className='w-full md:px-14 min-h-screen bg-gradient-to-b from-blue-950 to-black text-white'>
        <div className='max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full h-full'>
            <div className='pb-8'>
                <p className='text-4xl font-bold inline border-b-4 border-gray-500'>
                    About
                </p>
            </div>
            <p className='text-xl mt-5'>
                Hi there! Glad you have found me. Here you will find some of the projects I have made and a little background information about me. 
            </p>
            <br />
            <p className='text-xl'>
                I graduated from the University of Warwick and am now a web developer who specialises in creating custom Wordpress sites.
            </p>
            <br />
            <p className='text-xl'>
                I will be continuing to add new features and projects on here so you can keep track of my progress.
            </p>
            <br />
            <p className='text-xl'>
                Have a sceroll through the rest of my site and feel free to connect with me on LinkedIn. 
            </p>
        </div>
    </div>
  );
};

export default About;