import React from 'react';
import { FaGithub, FaLinkedin } from 'react-icons/fa';
import { HiOutlineMail } from 'react-icons/hi';

const Footer = () => {

    const links = [
        {
        id: 1,
        child: (
            <>
            <FaLinkedin size={30}/>
            </>
        ),
        href: 'https://www.linkedin.com/in/nesar-torkamani-25841b152/',
        style: 'rounded-tr-md bg-blue-500'
    },
    {
        id: 2,
        child: (
            <>
             <FaGithub size={30}/>
            </>
        ),
        href: 'https://github.com/nesartorkamani', 
        style: 'bg-gray-800'
    },
    {
        id: 3,
        child: (
            <>
            <HiOutlineMail size={30}/>
            </>
        ),
        href: 'https://mailto:nesartorkamani@hotmail.com',
        style: 'rounded-br-md bg-green-500'
    }
]

  return (
    <div className='lg:hidden flex justify-center items-center w-full h-20 text-white bg-blue-950 px-4'>
        <ul className='lg:hidden flex space-x-4'>
            {links.map(({id, child, href, style}) => (
            <li key={id} className={'flex justify-between items-center h-14 px-4  bg-gray-500 hover:scale-110 hover:rounded-md duration-300' + ' ' + style}>
            <a href={href} className='flex justify-between space-between items-center w-full text-white' target='_blank' rel='noreferror'>
                <>
                {child}
                </>
            </a>
        </li>
            ))}
        </ul>
    <div>
    </div>
    </div>
  )
}

export default Footer